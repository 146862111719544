import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";
import Container from "../components/container";
import Columns from "../components/columns";
import ContactForm from "../components/ContactForm";
import styles from "./kontakt.module.css";
import { FaInfo, FaPhone } from "react-icons/fa";
import Layout from "../components/layout";
import Hero from "../components/HeroBanner";

export const query = graphql`
  query {
    heroBg: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Projekty = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Program partnerski"
        description="Program partnerski Empireo. Sprawdź, jak może wyglądać nasza współpraca."
        keywords={["zarządzanie projektami"]}
      />
      <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>Rozwijaj z nami Empireo</h1>
        <h2>Program partnerski</h2>
      </Hero>
      <Container className={styles.wrapper}>
        <div className={styles.formWrapper}>
          <div className={styles.contactHeader}>
            <p>
              <strong>Porozmawiajmy o współpracy</strong>
            </p>
          </div>
          <ContactForm systemType="empireo" />
        </div>

        <div className={styles.mapContainer}>
          <div className={styles.contactHeader}>
            <FaInfo size="50px" />
            <h2
              style={{
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Wspólnie stwórzmy najlepszy system dla branży reklamowej{" "}
            </h2>
            <p
              style={{
                textAlign: "left",
                marginTop: 20,
              }}
            >
              Masz <strong>doświadczenie w branży reklamowej</strong>? Chcesz
              wspólnie z nami stworzyć <strong>najlepszy system</strong> do
              zarządzania agencją reklamową i marketingową?{" "}
            </p>
            <p
              style={{
                textAlign: "left",
                marginTop: 20,
              }}
            >
              {" "}
              Oferujemy współpracę nad rozwojem systemu, która jest{" "}
              <strong>korzystna dla obu stron</strong>. Dołącz do programu
              partnerskiego, aby otrzymać <strong>atrakcyjne benefity</strong>.
            </p>
            <p
              style={{
                textAlign: "left",
                marginTop: 20,
              }}
            >
              Skontaktuj się z nami, aby wspólnie określić, jak może wyglądać
              nasza współpraca.
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Projekty;
